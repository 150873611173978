import React, { useEffect, useState } from "react";
import NavbarPages from "../Components/NavbarPages";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import {
  FaStore,
  FaTrademark,
  FaDesktop,
  FaCamera,
  FaBoxes,
  FaChartLine,
  FaPercent,
  FaCalendarAlt,
  FaFileContract,
  FaMoneyBillWave,
  FaChevronUp,
  FaChevronDown,
  FaSearch,
} from "react-icons/fa";
import FAQ from "../Components/FAQ";

const ServiceCard = ({ title, description, color, image }) => {
  return (
    <div className="flex flex-col items-center justify-center h-96 w-11/12 mx-auto bg-white">
      <h2 className="text-3xl font-bold text-center my-4">{title}</h2>
      <div className="group/card h-full w-full [perspective:1000px]">
        <div className="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover/card:[transform:rotateY(180deg)]">
          <div
            className={`absolute inset-0 h-full w-full flex items-center justify-center rounded-xl ${color}`}
          >
            {/* <p className="text-3xl font-bold text-white text-center mt-4 sm:mt-8 mb-8 sm:w-1/2">
              {title}
            </p> */}
            <img
              src={image}
              alt=""
              className="w-7/12 sm:1/3 md:2/3 2xl:w-1/3"
            />
          </div>
          <div className="absolute inset-0 h-full w-full rounded-xl bg-pink-800/90 px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
            <div className="flex min-h-full flex-col items-center justify-center">
              {/* <h1 className="text-xl font-bold">{title}</h1> */}
              <p className="sm:text-xl 2xl:text-2xl ">{description}</p>

              <Link
                to="/contacto"
                className="mt-2 relative inline-flex items-center
                justify-start px-6 py-3 overflow-hidden font-medium
                transition-all bg-white/30 rounded hover:bg-white group/but"
              >
                <span className="w-48 h-48 rounded rotate-[-40deg] bg-[#FFB6C1] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover/but:ml-0 group-hover/but:mb-32 group-hover/but:translate-x-0"></span>
                <span className="relative w-full text-left text-white transition-colors duration-300 ease-in-out group-hover/but:text-white">
                  Adquirir
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InvestmentModel = () => {
  return (
    <div className="w-full bg-gradient-to-b from-pink-100 to-white py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-5xl font-extrabold text-pink-600 text-center mb-16 relative">
          Modelo de Inversión
          <span className="absolute bottom-0 left-1/2 w-24 h-1 bg-pink-400 transform -translate-x-1/2 mt-4"></span>
        </h2>
        <div className="grid md:grid-cols-2 gap-12">
          <div className="bg-white rounded-2xl shadow-2xl p-8 transform hover:scale-105 transition-transform duration-300">
            <h3 className="text-3xl font-bold text-pink-500 mb-6 flex items-center">
              <FaStore className="mr-3" /> Inversión Inicial
            </h3>
            <ul className="space-y-4">
              {[
                { icon: FaStore, text: "Kiosco (Máquina)" },
                {
                  icon: FaTrademark,
                  text: "Licencia de uso de marca reconocida y probada",
                },
                { icon: FaDesktop, text: "Software" },
                { icon: FaCamera, text: "Cámara IP" },
                { icon: FaBoxes, text: "Inventario inicial (1.5 meses)" },
              ].map((item, index) => (
                <li key={index} className="flex items-center text-gray-700">
                  <item.icon className="mr-3 text-pink-400" />
                  {item.text}
                </li>
              ))}
            </ul>
            <p className="text-4xl font-bold text-pink-600 mt-8 text-center">
              $439,000 MXN <span className="text-sm">(IVA incluido)</span>
            </p>
            <p className="text-sm text-gray-500 mt-2 text-center">
              *No incluye gastos preoperativos
            </p>
          </div>
          <div className="bg-white rounded-2xl shadow-2xl p-8 transform hover:scale-105 transition-transform duration-300">
            <h3 className="text-3xl font-bold text-pink-500 mb-6 flex items-center">
              <FaChartLine className="mr-3" /> Indicadores Financieros
            </h3>
            <ul className="space-y-4">
              {[
                {
                  icon: FaStore,
                  text: "Ventas esperadas por día:",
                  value: "30-90 algodones",
                },
                {
                  icon: FaPercent,
                  text: "Margen de utilidad esperada:",
                  value: "60%",
                },
                {
                  icon: FaCalendarAlt,
                  text: "Retorno de inversión:",
                  value: "6-12 meses",
                },
                {
                  icon: FaFileContract,
                  text: "Vigencia de contrato:",
                  value: "3 años",
                },
                {
                  icon: FaMoneyBillWave,
                  text: "Regalías:",
                  value: "5% mensuales sobre ventas",
                },
              ].map((item, index) => (
                <li key={index} className="flex items-center text-gray-700">
                  <item.icon className="mr-3 text-pink-400" />
                  <span className="font-semibold mr-2">{item.text}</span>{" "}
                  {item.value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const faqs = [
  {
    question: "¿Qué es Magic Cotton Candy?",
    answer:
      "Magic Cotton Candy es un concepto especializado en máquinas expendedoras automáticas e interactivas de algodón de azúcar. Ofrecemos una experiencia única y divertida para los clientes, con una variedad de diseños, colores y sabores personalizados.",
  },
  {
    question: "¿Cuál es la inversión inicial requerida?",
    answer:
      "La inversión inicial es de $439,000 MXN (IVA incluido), que incluye el kiosco (máquina), licencia de uso de marca, software, cámara IP, e inventario inicial para 1.5 meses. No incluye gastos preoperativos.",
  },
  {
    question: "¿Cuánto espacio necesito para instalar una máquina?",
    answer:
      "Nuestras máquinas son compactas y solo requieren 1.5 metros cuadrados de espacio y una conexión eléctrica.",
  },
  {
    question: "¿Cuál es el margen de utilidad esperado?",
    answer: "El margen de utilidad esperado es del 60%.",
  },
  {
    question: "¿Cuánto tiempo toma recuperar la inversión?",
    answer:
      "El retorno de inversión esperado es de 6 a 12 meses, dependiendo de las ventas y la ubicación.",
  },
  {
    question: "¿Cuál es la duración del contrato de licencia?",
    answer: "El contrato de licencia tiene una vigencia de 3 años.",
  },
  {
    question: "¿Cuáles son las regalías?",
    answer: "Las regalías son del 5% mensual sobre las ventas.",
  },
  {
    question: "¿Qué tipo de soporte ofrecen a los licenciatarios?",
    answer:
      "Ofrecemos capacitación, manuales operativos detallados, soporte técnico continuo, acceso a recursos exclusivos en nuestra plataforma Magic Cotton Candy Support Hub, y proveeduría exclusiva.",
  },
  {
    question: "¿Las máquinas requieren mucho mantenimiento?",
    answer:
      "No, nuestras máquinas están diseñadas para ser de bajo mantenimiento y cuentan con un sistema de autolimpieza después de cada producción.",
  },
  {
    question: "¿Puedo operar múltiples máquinas?",
    answer:
      "Sí, puedes administrar varias máquinas desde tu celular, tableta o computadora, lo que te permite generar ingresos pasivos y compaginar este negocio con otras actividades.",
  },
];

const FranchisePage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showBackToTop, setShowBackToTop] = useState(false);

  const services = [
    {
      title: "DISEÑO ATRACTIVO",
      description:
        "Nuestras unidades de Magic Cotton Candy están diseñadas para atraer la atención y crear una experiencia única para tus clientes.",
      color: "bg-pink-500",
      image: "/images/franchise/diseño-atractivo.png",
    },
    {
      title: "BAJA INVERSIÓN",
      description:
        "Adquiere un kiosco automatizado de Magic Cotton Candy a un costo justo y accesible, y comienza tu camino como emprendedor con confianza.",
      color: "bg-pink-300",
      image: "/images/franchise/baja-inversion.png",
    },
    {
      title: "INNOVACIÓN",
      description:
        "Nuestras unidades están listas para generar ingresos desde el momento en que se instalan. No necesitas licencias ni enfrentar complicaciones comunes en otros negocios. Sorprende a tu público con la innovación de Magic Cotton Candy.",
      color: "bg-pink-100",
      image: "/images/franchise/innovacion.png",
    },
    {
      title: "ESPACIO REDUCIDO",
      description:
        "Con solo 1 metro cuadrado y conexión eléctrica, nuestras máquinas funcionan sin complicaciones de voltaje. La eficiencia en el espacio es clave para tu negocio.",
      color: "bg-pink-500",
      image: "/images/franchise/espacio.png",
    },
    {
      title: "INTERACCIÓN DIVERTIDA",
      description:
        "Nuestra máquina 100% automatizada garantiza una experiencia divertida e interactiva con tus clientes. Además, utilizamos insumos de popotes de papel eco-friendly biodegradables para cuidar el medio ambiente.",
      color: "bg-pink-300",
      image: "/images/franchise/diversion.png",
    },
    {
      title: "AUTOLIMPIEZA",
      description:
        "Mantén todo pulcro e higiénico. Nuestra máquina se autolimpia después de cada producción, asegurando la mejor calidad en cada algodón de azúcar.",
      color: "bg-pink-100",
      image: "/images/franchise/autolimpieza.png",
    },

    {
      title: "ALTA RENTABILIDAD",
      description:
        "Con un bajo costo de producción y una demanda constante, Magic Cotton Candy ofrece una alta rentabilidad. Un éxito total para jóvenes y menos jóvenes.",
      color: "bg-pink-500",
      image: "/images/franchise/rentabilidad.png",
    },
    {
      title: "INGRESOS PASIVOS",
      description:
        "Nuestras máquinas te brindan la posibilidad de generar ingresos pasivos. Administra varias máquinas desde tu celular, tableta o computadora, y compagina este negocio con otros trabajos de forma simultánea.",
      color: "bg-pink-300",
      image: "/images/franchise/pasivo.png",
    },
    {
      title: "TECNOLOGÍA AVANZADA",
      description:
        "Magic Cotton Candy utiliza tecnología de punta para una gestión clara y eficiente. Controla tu negocio con telemetría absoluta, informes de ventas en tiempo real, control de información de insumos y programación de alarmas.",
      color: "bg-pink-100",
      image: "/images/franchise/tecnologia.png",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const filteredServices = services.filter((service) =>
    service.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="min-h-screen w-full">
      <NavbarPages currentPage="franchise" />

      {/* Hero Section */}
      <div className="relative bg-pink-100 flex items-center justify-center">
        <div className="w-11/12 md:w-2/3 xl:w-1/2 text-center mt-40">
          <h1 className="text-5xl font-bold text-pink-600 mb-4">
            Únete a la Familia Magic Cotton Candy
          </h1>
          <p className="text-xl sm:text-2xl text-gray-700 text-center mb-12">
            Si quieres ser parte de nuestra familia, puedes adquirir una de
            nuestras <span className="font-bold text-pink-400">máquinas</span> y
            empezar a vender algodón de azúcar de la mejor calidad. !Comienza tu
            viaje empresarial hoy!
          </p>
          <Link
            to="/contacto"
            className="bg-pink-500 text-white px-6 py-3 rounded-full hover:bg-pink-600 transition duration-300"
          >
            Comienza Ahora
          </Link>
        </div>
      </div>

      {/* <InvestmentModel /> */}

      <div className="container mx-auto px-4">
        {/* Services Section */}
        <div className="my-20">
          <h2 className="text-4xl font-bold text-pink-600 text-center mb-12">
            Beneficios de Nuestra Franquicia
          </h2>

          {/* Search Input */}
          <div className="mx-auto relative mb-8 w-96">
            <input
              type="text"
              placeholder="Buscar servicios..."
              className="w-full p-3 pl-10 border border-pink-300 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>

          {/* Service Cards */}
          {filteredServices.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filteredServices.map((service, index) => (
                <ServiceCard key={index} {...service} />
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-600">
              No se encontraron servicios que coincidan con tu búsqueda.
            </p>
          )}
        </div>

        <div className="my-20">
          <h2 className="text-4xl font-bold text-pink-600 text-center mb-12">
            Preguntas Frecuentes
          </h2>
          <FAQ faqs={faqs} />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FranchisePage;
